import { useContext } from "react";
import { LoadingContextType, LoadingContext } from "./LoadingProvider";

/**
 * useLoading
 * @returns
 */
export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("LoadingProvider 내에서만 사용가능합니다.");
  }
  return context;
};
