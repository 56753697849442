import { createContext, ReactNode, useState, FC, useContext } from "react";
import Modal, { ModalPropsTypes } from "@/components/common/Modal";

/** ===== Types ===== */
interface ModalInstance extends Omit<ModalPropsTypes, "open" | "onClose"> {
  id: string;
}

export type ModalContextType = {
  showModal: (
    id: string,
    props: Omit<ModalPropsTypes, "open" | "onClose">
  ) => void;
  hideModal: (id: string) => void;
};

/** ===== Context ===== */
export const ModalContext = createContext<ModalContextType>({
  hideModal: () => {},
  showModal: () => {},
});

export const useModalContext = () => useContext(ModalContext);

/** ===== Provider ===== */
export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<ModalInstance[]>([]);

  const showModal = (
    id: string,
    props: Omit<ModalPropsTypes, "open" | "onClose">
  ) => {
    // Ensure the id is unique to prevent duplicates
    if (modals.some((modal) => modal.id === id)) {
      console.error(`A modal with the id "${id}" already exists.`);
      return;
    }
    setModals((currentModals) => [...currentModals, { ...props, id }]);
  };

  const hideModal = (id: string) => {
    setModals((currentModals) =>
      currentModals.filter((modal) => modal.id !== id)
    );
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modals.map((modal) => (
        <Modal
          key={modal.id}
          {...modal}
          open={true}
          onClose={() => hideModal(modal.id)}
        >
          {modal.children}
        </Modal>
      ))}
    </ModalContext.Provider>
  );
};
