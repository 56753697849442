import { TransientProps } from "@/types";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

interface BadgePropsTypes {
  // type?: "default(solid)" | "line";
  // boader : line, background : white / default(gray) | black | red | sky | green
  outline?: boolean;
  // boader : line, background : transparent / default(gray) | black | red | sky | green | white
  ghost?: boolean;
  // extralarge | large | default(middle) | small | extrasmall
  size?: string;
  // default(gray) | black | navy | blue | sky | green | red | yellow | white | lightgray | lightsky | lightred | lightgreen | lightyellow | custom;
  color?: string;
  // default(rectangle) | capsule |
  shape?: string;
  // offset?: [number, number];
  children?: string | ReactNode;
}
interface Size {
  minWidth?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
}
interface Color {
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  lineColor?: string;
  lineBackgroundColor?: string;
  ghostColor?: string;
}

/**
 * 뱃지 컴포넌트
 * @param props
 * @returns
 */
const Badge: React.FC<BadgePropsTypes> = (props: BadgePropsTypes) => {
  const { children, ghost = false, outline = false, ...rest } = props;

  return (
    <>
      <StyledBadge {...rest} $ghost={ghost} $outline={outline}>
        {children}
      </StyledBadge>
    </>
  );
};

export default Badge;

const sizes: { [key in string]: Size } = {
  extrasmall: {
    minWidth: "39px",
    height: "18px",
    padding: "0 6px",
    fontSize: "9px",
  },
  small: {
    minWidth: "42px",
    height: "20px",
    padding: "0 6px",
    fontSize: "10px",
  },
  default: {
    minWidth: "47px",
    height: "22px",
    padding: "0 7px",
    fontSize: "11px",
  },
  large: {
    minWidth: "52px",
    height: "24px",
    padding: "0 8px",
    fontSize: "12px",
  },
};
const sizeStyles = css<Pick<BadgeProps, "size" | "shape">>`
  ${({ size = "default", shape = "default" }) => {
    // default, capsule;
    // large | default(middle) | small | extrasmall
    let borderRadius = "0px";
    switch (shape) {
      case "default":
        if (size === "extrasmall") borderRadius = "5px";
        if (size === "small") borderRadius = "6px";
        if (size === "default") borderRadius = "6px";
        if (size === "large") borderRadius = "6px";
        break;

      case "capsule":
        if (size === "extrasmall") borderRadius = "9px";
        if (size === "small") borderRadius = "10px";
        if (size === "default") borderRadius = "12px";
        if (size === "large") borderRadius = "12px";
        break;
    }
    return css`
      min-width: ${sizes[size].minWidth};
      height: ${sizes[size].height};
      padding: ${sizes[size].padding};
      font-size: ${sizes[size].fontSize};
      font-weight: 700;
      border-radius: ${borderRadius};
    `;
  }}
`;

const colors: { [key in string]: Color } = {
  default: {
    color: "var(--color-white)",
    borderColor: "var(--color-black60)",
    backgroundColor: "var(--color-black60)",
    lineColor: "var(--color-black70)",
    lineBackgroundColor: "var(--color-white)",
  },
  black: {
    color: "var(--color-white)",
    borderColor: "var(--color-black90)",
    backgroundColor: "var(--color-black90)",
    lineColor: "var(--color-black90)",
    lineBackgroundColor: "var(--color-white)",
  },
  navy: {
    color: "var(--color-white)",
    borderColor: "var(--color-navy70)",
    backgroundColor: "var(--color-navy70)",
    lineColor: "var(--color-navy70)",
    lineBackgroundColor: "var(--color-white)",
  },
  blue: {
    color: "var(--color-white)",
    borderColor: "var(--color-blue60)",
    backgroundColor: "var(--color-blue60)",
    lineColor: "var(--color-blue60)",
    lineBackgroundColor: "var(--color-white)",
  },
  sky: {
    color: "var(--color-white)",
    borderColor: "var(--color-sky60)",
    backgroundColor: "var(--color-sky60)",
    lineColor: "var(--color-sky60)",
    lineBackgroundColor: "var(--color-white)",
  },
  green: {
    color: "var(--color-white)",
    borderColor: "var(--color-green60)",
    backgroundColor: "var(--color-green60)",
    lineColor: "var(--color-green60)",
    lineBackgroundColor: "var(--color-white)",
  },
  red: {
    color: "var(--color-white)",
    borderColor: "var(--color-red60)",
    backgroundColor: "var(--color-red60)",
    lineColor: "var(--color-red70)",
    lineBackgroundColor: "var(--color-white)",
  },
  yellow: {
    color: "var(--color-black90)",
    borderColor: "var(--color-green50)",
    backgroundColor: "var(--color-green50)",
    lineColor: "var(--color-green5090)",
    lineBackgroundColor: "var(--color-white)",
  },
  white: {
    color: "var(--color-black100)",
    borderColor: "var(--color-white)",
    backgroundColor: "var(--color-white)",
    lineColor: "var(--color-black90)",
    lineBackgroundColor: "var(--color-white)",
    ghostColor: "var(--color-white)",
  },
  lightgray: {
    color: "var(--color-black90)",
    borderColor: "var(--color-black35)",
    backgroundColor: "var(--color-black35)",
    lineColor: "var(--color-black90)",
    lineBackgroundColor: "transparent",
  },
  lightsky: {
    color: "var(--color-sky60)",
    borderColor: "var(--color-sky10)",
    backgroundColor: "var(--color-sky10)",
    lineColor: "var(--color-sky60)",
    lineBackgroundColor: "transparent",
  },
  lightred: {
    color: "var(--color-red70)",
    borderColor: "var(--color-red10)",
    backgroundColor: "var(--color-red10)",
    lineColor: "var(--color-red70)",
    lineBackgroundColor: "transparent",
  },
  lightgreen: {
    color: "var(--color-green60)",
    borderColor: "var(--color-green10)",
    backgroundColor: "var(--color-green10)",
    lineColor: "var(--color-green60)",
    lineBackgroundColor: "transparent",
  },
  lightyellow: {
    color: "var(--color-green5090)",
    borderColor: "var(--color-green5010)",
    backgroundColor: "var(--color-green5010)",
    lineColor: "var(--color-green5090)",
    lineBackgroundColor: "transparent",
  },
  custom: {
    color: "var(--color-white)",
    borderColor: "var(--color-navy80)",
    backgroundColor: "var(--color-navy80)",
    lineColor: "var(--color-black90)",
    lineBackgroundColor: "var(--color-white)",
  },
};
const colorStyles = css<Pick<BadgeProps, "color" | "$outline" | "$ghost">>`
  ${({ color = "default", $outline, $ghost }) => css`
    color: ${!$outline && !$ghost
      ? `${colors[color].color}`
      : `${colors[color].lineColor}`};
    border-color: ${colors[color].borderColor};
    background-color: ${!$outline && !$ghost
      ? `${colors[color].backgroundColor}`
      : ``};
  `}
`;
const outlineStyle = css<BadgePropsTypes>`
  ${({ color = "default", outline }) =>
    outline &&
    css`
      background-color: ${colors[color].lineBackgroundColor};
    `}
`;
const ghostStyle = css<BadgePropsTypes>`
  ${({ color = "default", ghost }) =>
    ghost &&
    css`
      ${color === "white" && `color: ${colors[color].ghostColor}`};
    `}
`;

const StyledBadge = styled.span<BadgeProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background: transparent;

  ${sizeStyles}
  ${colorStyles}
  ${outlineStyle}
  ${ghostStyle}
`;

type BadgeProps = TransientProps<BadgePropsTypes, "outline" | "ghost">;
