import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export default function AsideView({
  pathname,
  menuList,
}: {
  pathname: string;
  menuList: { title: string; url: string }[];
}) {
  return (
    <StyledAside>
      <h2 className="sr-only">Component</h2>
      <h5 className="">기본</h5>
      <ul className="aside-menu" id="aside-menu">
        {menuList
          .slice(0, 7)
          // .sort((a, b) => a.title.localeCompare(b.title))
          .map((menu) => {
            return (
              <li
                key={menu.url}
                className={pathname === menu.url ? "active" : ""}
              >
                <Link to={menu.url}>
                  <span>{menu.title}</span>
                </Link>
              </li>
            );
          })}
      </ul>
      {/* <h5 className="">레이아웃</h5> */}
      <h5 className="">디스플레이</h5>
      <ul className="aside-menu" id="aside-menu">
        {menuList
          .slice(7, 13)
          // .sort((a, b) => a.title.localeCompare(b.title))
          .map((menu) => {
            return (
              <li
                key={menu.url}
                className={pathname === menu.url ? "active" : ""}
              >
                <Link to={menu.url}>
                  <span>{menu.title}</span>
                </Link>
              </li>
            );
          })}
      </ul>
      <h5 className="">유틸</h5>
      <ul className="aside-menu" id="aside-menu">
        {menuList
          .slice(14, 23)
          // .sort((a, b) => a.title.localeCompare(b.title))
          .map((menu) => {
            return (
              <li
                key={menu.url}
                className={pathname === menu.url ? "active" : ""}
              >
                <Link to={menu.url}>
                  <span>{menu.title}</span>
                </Link>
              </li>
            );
          })}
      </ul>
    </StyledAside>
  );
}

const asideLink = css`
  color: var(--color-black85);
  border-left-color: var(--color-green50);
`;

const StyledAside = styled.aside`
  position: fixed;
  width: 22rem;
  height: calc(100vh - 6.4rem);
  /* padding: 20px 30px 0 20px; */
  padding: 2rem 0;
  border-right: 1px solid var(--color-black25);
  background-color: var(--color-white);
  overflow-y: scroll;
  h2 {
    margin-bottom: 2rem;
    padding-bottom: 1.8rem;
    font-size: 1.8rem;
    border-bottom: 2px solid var(--color-black30);
  }
  h5 {
    padding-top: 1.8rem;
    padding-left: 1.8rem;
  }
  .aside-menu {
    li {
      a {
        display: block;
        padding: 0.6rem 2.4rem;
        color: var(--color-black50);
        font-size: 1.4rem;
        font-weight: 700;
        border-left: 5px solid transparent;
        transition: 0.3s all;
        &:hover {
          ${asideLink}
        }
      }
      &.active {
        a {
          ${asideLink}
        }
      }
    }
  }
`;
