import Provider from "@/Provider";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "../routes";

/** ===== Providers ===== */
function Providers() {
  return (
    <Provider>
      <Suspense fallback={`...loading`}>
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
}

/** ===== Exports ===== */

export default Providers;
