import { lazy, memo } from "react";
import { createBrowserRouter } from "react-router-dom";
import Wrap from "@/components/templates/Layout/Wrap";

const Main = lazy(() => import("../pages/Main"));
const Avatar = lazy(() => import("../pages/Avatar"));
const Button = lazy(() => import("../pages/Button"));
const Table = lazy(() => import("../pages/Table"));
const Pagination = lazy(() => import("../pages/Pagination"));
const Typography = lazy(() => import("../pages/Typography"));
const Divider = lazy(() => import("../pages/Divider"));
const Breadcrumb = lazy(() => import("../pages/Breadcrumb"));
const Dropdown = lazy(() => import("../pages/Dropdown"));
const Checkbox = lazy(() => import("../pages/Checkbox"));
const Input = lazy(() => import("../pages/Input"));
const InputNumber = lazy(() => import("../pages/InputNumber"));
const Radio = lazy(() => import("../pages/Radio"));
const Select = lazy(() => import("../pages/Select"));
const Badge = lazy(() => import("../pages/Badge"));
const Tabs = lazy(() => import("../pages/Tabs"));
const Tag = lazy(() => import("../pages/Tag"));
const Tooltip = lazy(() => import("../pages/Tooltip"));
const Modal = lazy(() => import("../pages/Modal"));
const Spin = lazy(() => import("../pages/Spin"));
const Codeblock = lazy(() => import("../pages/Codeblock"));
const Icon = lazy(() => import("../pages/Icon"));
const Form = lazy(() => import("../pages/Form"));
const Editor = lazy(() => import("../pages/Editor"));
const Rolling = lazy(() => import("../pages/Rolling"));
const Switch = lazy(() => import("../pages/Switch"));
const Upload = lazy(() => import("../pages/Upload"));
const Menu = lazy(() => import("../pages/Menu"));
const Skeleton = lazy(() => import("../pages/Skeleton"));
const Card = lazy(() => import("../pages/Card"));

const Layout = memo(Wrap);

export const router = createBrowserRouter([
  { path: "/", element: <Main /> },
  {
    path: "/aurora",
    element: <Layout />,
    children: [
      {
        path: "avatar",
        element: <Avatar />,
      },
      {
        path: "button",
        element: <Button />,
      },
      {
        path: "typography",
        element: <Typography />,
      },
      {
        path: "divider",
        element: <Divider />,
      },
      {
        path: "breadcrumb",
        element: <Breadcrumb />,
      },
      {
        path: "dropdown",
        element: <Dropdown />,
      },
      {
        path: "checkbox",
        element: <Checkbox />,
      },
      {
        path: "input",
        element: <Input />,
      },
      {
        path: "inputNumber",
        element: <InputNumber />,
      },
      {
        path: "radio",
        element: <Radio />,
      },
      {
        path: "select",
        element: <Select />,
      },
      {
        path: "badge",
        element: <Badge />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "tag",
        element: <Tag />,
      },
      {
        path: "tooltip",
        element: <Tooltip />,
      },
      {
        path: "modal",
        element: <Modal />,
      },
      {
        path: "spin",
        element: <Spin />,
      },
      {
        path: "table",
        element: <Table />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "codeblock",
        element: <Codeblock />,
      },
      {
        path: "icon",
        element: <Icon />,
      },
      {
        path: "form",
        element: <Form />,
      },
      {
        path: "editor",
        element: <Editor />,
      },
      {
        path: "rolling",
        element: <Rolling />,
      },
      {
        path: "switch",
        element: <Switch />,
      },
      {
        path: "upload",
        element: <Upload />,
      },
      {
        path: "menu",
        element: <Menu />,
      },
      {
        path: "skeleton",
        element: <Skeleton />,
      },
      {
        path: "card",
        element: <Card />,
      },
    ],
  },
]);
