import { forwardRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { Spin } from "@/components/common";

import { useLoading } from "@hooks/useLoading";
import { ButtonProps } from "./Helper";
import { TransientBooleaProps } from "@/types";

interface Size {
  minWidth?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
  lineHeight?: string;
  borderRadius?: string;
  btnIconWidth?: string;
  btnIconMarginL?: string;
}

interface Color {
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  pressedColor?: string;
  pressedBackgroundColor?: string;
  pressedBorderColor?: string;
  disabledColor?: string;
  disabledBackgroundColor?: string;
  disabledBorderColor?: string;
  loadingBorderColor?: string;
  loadingBackgroundColor?: string;
}

/**
 * 버튼 컴포넌트
 * @param props
 * @returns
 */
const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      disabled = false,
      variant = "default",
      htmlType,
      ghost = false,
      outline = false,
      block = false,
      shape = "default",
      icon,
      iconBack = false,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const { loading: isLoading, setLoading } = useLoading();

    useEffect(() => {
      if (loading !== undefined) {
        setLoading(loading);
      }
    }, [loading, setLoading]);

    return (
      <StyledButton
        {...rest}
        type={htmlType}
        disabled={disabled}
        $ghost={ghost}
        $outline={outline}
        $loading={loading}
        $block={block}
        icon={icon}
        $iconBack={iconBack}
        shape={shape}
        ref={ref}
        variant={variant}
      >
        {isLoading && <Spin spinning={true} size="small"></Spin>}
        {!iconBack && icon && icon}
        {children && <span>{children}</span>}
        {iconBack && icon && icon}
      </StyledButton>
    );
  },
);

const sizes: { [key in string]: Size } = {
  extrasmall: {
    minWidth: "35px",
    height: "32px",
    padding: "0 8px",
    fontSize: "13px",
    lineHeight: "16px",
    borderRadius: "",
    btnIconWidth: "12px",
    btnIconMarginL: "2px",
  },
  small: {
    minWidth: "43px",
    height: "36px",
    padding: "0 12px",
    fontSize: "13px",
    lineHeight: "16px",
    borderRadius: "",
    btnIconWidth: "12px",
    btnIconMarginL: "2px",
  },
  default: {
    minWidth: "53px",
    height: "44px",
    padding: "0 16px",
    fontSize: "14px",
    lineHeight: "17px",
    borderRadius: "",
    btnIconWidth: "16px",
    btnIconMarginL: "4px",
  },
  large: {
    minWidth: "63px",
    height: "52px",
    padding: "0 20px",
    fontSize: "17px",
    lineHeight: "20px",
    borderRadius: "",
    btnIconWidth: "18px",
    btnIconMarginL: "4px",
  },
  extralarge: {
    minWidth: "65px",
    height: "60px",
    padding: "0 20px",
    fontSize: "17px",
    lineHeight: "20px",
    borderRadius: "",
    btnIconWidth: "20px",
    btnIconMarginL: "4px",
  },
};
// sizeStyles 수정
const sizeStyles = css<TButtonProps>`
  ${({ size = "default", shape, $loading, variant }) => {
    // default, capsule, circle, squaure;
    // extralarge | large | default(middle) | small | extrasmall
    let borderRadius = "0px";
    switch (shape) {
      case "default":
        if (size === "extrasmall") borderRadius = "8px";
        if (size === "small") borderRadius = "8px";
        if (size === "default") borderRadius = "10px";
        if (size === "large") borderRadius = "12px";
        if (size === "extralarge") borderRadius = "12px";
        break;

      case "square":
        break;

      case "circle":
        borderRadius = "50%";
        break;

      case "capsule":
        if (size === "extrasmall") borderRadius = "16px";
        if (size === "small") borderRadius = "18px";
        if (size === "default") borderRadius = "22px";
        if (size === "large") borderRadius = "26px";
        if (size === "extralarge") borderRadius = "30px";

        break;
    }

    return css`
      ${shape !== "circle" && `min-width: ${sizes[size].minWidth}`};
      ${shape === "circle" && `width: ${sizes[size].height}`};
      height: ${variant === "text" ? `` : `${sizes[size].height}`};
      padding: ${shape !== "circle"
        ? variant === "text"
          ? ""
          : sizes[size]?.padding
        : ""};
      font-size: ${!$loading ? `${sizes[size].fontSize}` : "0"};
      line-height: ${!$loading ? `${sizes[size].lineHeight}` : "0"};
      border-radius: ${borderRadius};
      > img,
      > svg {
        vertical-align: middle;
        height: auto;
        width: ${sizes[size].btnIconWidth};
        & + span {
          margin-left: ${sizes[size].btnIconMarginL};
        }
      }
      span + img,
      span + svg {
        margin-left: ${sizes[size].btnIconMarginL};
      }
    `;
  }}
`;

/* color : yellow, gray, default[lightgray], navy, red, white */
const colors: { [key in string]: Color } = {
  default: {
    color: "var(--color-black90)",
    borderColor: "var(--color-black15)",
    backgroundColor: "var(--color-black15)",
    pressedColor: "var(--color-black90)",
    pressedBackgroundColor: "var(--color-black25)",
    pressedBorderColor: "var(--color-black25)",
    disabledColor: "var(--color-black55)",
    disabledBackgroundColor: "var(--color-black30)",
    disabledBorderColor: "var(--color-black30)",
    loadingBorderColor: "var(--color-black15)",
    loadingBackgroundColor: "var(--color-black15)",
  },
  yellow: {
    color: "var(--color-black90)",
    borderColor: "var(--color-green50)",
    backgroundColor: "var(--color-green50)",
    pressedColor: "var(--color-black90)",
    pressedBackgroundColor: "var(--btn-yellow-pressed)",
    pressedBorderColor: "var(--btn-yellow-pressed)",
    disabledColor: "var(--color-black55)",
    disabledBackgroundColor: "var(--color-black30)",
    disabledBorderColor: "var(--color-black30)",
    loadingBorderColor: "var(--color-green5020)",
    loadingBackgroundColor: "var(--color-green5020)",
  },
  gray: {
    color: "var(--color-black90)",
    borderColor: "var(--color-black35)",
    backgroundColor: "var(--color-black35)",
    pressedColor: "var(--color-black90)",
    pressedBackgroundColor: "var(--color-black40)",
    pressedBorderColor: "var(--color-black40)",
    disabledColor: "var(--color-black55)",
    disabledBackgroundColor: "var(--color-black30)",
    disabledBorderColor: "var(--color-black30)",
    loadingBorderColor: "var(--color-black25)",
    loadingBackgroundColor: "var(--color-black25)",
  },
  navy: {
    color: "var(--color-white)",
    borderColor: "var(--color-navy80)",
    backgroundColor: "var(--color-navy80)",
    pressedColor: "var(--color-white)",
    pressedBackgroundColor: "var(--btn-navy-pressed)",
    pressedBorderColor: "var(--btn-navy-pressed)",
    disabledColor: "var(--color-black50)",
    disabledBackgroundColor: "var(--color-black20)",
    disabledBorderColor: "var(--color-black20)",
    loadingBorderColor: "var(--color-navy40)",
    loadingBackgroundColor: "var(--color-navy40)",
  },
  red: {
    color: "var(--color-white)",
    borderColor: "var(--color-red60)",
    backgroundColor: "var(--color-red60)",
    pressedColor: "var(--color-white)",
    pressedBackgroundColor: "var(--btn-red-pressed)",
    pressedBorderColor: "var(--btn-red-pressed)",
    disabledColor: "var(--color-black55)",
    disabledBackgroundColor: "var(--color-black30)",
    disabledBorderColor: "var(--color-black30)",
    loadingBorderColor: "var(--color-red30)",
    loadingBackgroundColor: "var(--color-red30)",
  },
  white: {
    color: "var(--color-black90)",
    borderColor: "var(--color-white)",
    backgroundColor: "var(--color-white)",
    pressedColor: "var(--color-black90)",
    pressedBackgroundColor: "var(--color-black25)",
    pressedBorderColor: "var(--color-black25)",
    disabledColor: "var(--color-black50)",
    disabledBackgroundColor: "var(--color-white)",
    disabledBorderColor: "var(--color-white)",
    loadingBorderColor: "var(--color-white)",
    loadingBackgroundColor: "var(--color-white)",
  },
};
// colorStyles 수정
const colorStyles = css<TButtonProps>`
  ${({ color = "default", $loading, variant }) => css`
    color: ${!$loading
      ? variant === "text"
        ? "var(--color-black70)"
        : colors[color]?.color
      : colors[color]?.loadingBackgroundColor};
    border-color: ${!$loading
      ? variant === "text"
        ? ""
        : colors[color]?.borderColor
      : colors[color]?.loadingBorderColor};
    background-color: ${!$loading
      ? variant === "text"
        ? ""
        : colors[color]?.backgroundColor
      : colors[color]?.loadingBackgroundColor};
    &.focus,
    &:focus,
    &.active,
    &:active {
      color: ${!$loading
        ? colors[color]?.pressedColor
        : colors[color]?.loadingBackgroundColor};
      background-color: ${!$loading
        ? variant === "text"
          ? ""
          : colors[color]?.pressedBackgroundColor
        : colors[color]?.loadingBackgroundColor};
      border-color: ${!$loading
        ? variant === "text"
          ? ""
          : colors[color]?.pressedBorderColor
        : colors[color]?.loadingBorderColor};
    }
    &.disabled,
    &.disabled.focus,
    &.disabled:focus,
    &[disabled],
    &[disabled].focus,
    &[disabled]:focus {
      color: ${colors[color]?.disabledColor};
      background-color: ${colors[color]?.disabledBackgroundColor};
      border-color: ${colors[color]?.disabledBorderColor};
    }
    span {
      ${variant === "text" && `border-bottom: 1px solid var(--color-black70)`};
    }
  `}
`;

const ghostStyle = css<TButtonProps>`
  ${({ $ghost, $loading }) =>
    $ghost &&
    css`
      color: ${!$loading ? "var(--color-black90)" : "transparent"};
      background-color: transparent;
      border-color: var(--color-black40);
      &.focus,
      &:focus,
      &.active,
      &:active {
        color: ${!$loading ? "var(--color-black90)" : "var(--color-white)"};
        background-color: ${!$loading
          ? "var(--btn-ghost-pressed)"
          : "transparent"};
        border-color: var(--color-black40);
      }
      &.disabled,
      &.disabled.focus,
      &.disabled:focus,
      &[disabled],
      &[disabled].focus,
      &[disabled]:focus {
        color: var(--color-black50);
        background-color: transparent;
        border-color: var(--color-black40);
      }
    `}
`;

const outlineStyle = css<TButtonProps>`
  ${({ $outline, $loading }) =>
    $outline &&
    css`
      color: ${!$loading ? "var(--color-black90)" : "var(--color-white)"};
      background-color: var(--color-white);
      border-color: var(--color-black40);
      &.focus,
      &:focus,
      &.active,
      &:active {
        color: ${!$loading ? "var(--color-black90)" : "var(--color-white)"};
        background-color: ${!$loading
          ? "var(--color-black15)"
          : "var(--color-white)"};
        border-color: var(--color-black40);
      }
      &.disabled,
      &.disabled.focus,
      &.disabled:focus,
      &[disabled],
      &[disabled].focus,
      &[disabled]:focus {
        color: var(--color-black40);
        background-color: var(--color-white);
        border-color: var(--color-black40);
      }
    `}
`;

const blockStyle = css<TButtonProps>`
  ${(props) =>
    props.$block &&
    css`
      /* display: block; */
      width: 100%;
    `}
`;

const StyledButton = styled.button<TButtonProps>`
  --btn-yellow-pressed: #2abfb8;
  --btn-navy-pressed: #172043;
  --btn-red-pressed: #d93023;
  --btn-ghost-pressed: rgba(0, 0, 0, 0.04);
  /* 공통 스타일 */
  /* display: inline-flex; */
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &.disabled,
  &.disabled.focus,
  &.disabled:focus,
  &[disabled],
  &[disabled].focus,
  &[disabled]:focus {
    cursor: not-allowed;
  }

  /* 크기 */
  // ${sizeStyles}

  /* 색상 */
  // ${colorStyles}

  // ${ghostStyle}
  // ${blockStyle}
  // ${outlineStyle}
`;

type TButtonProps = TransientBooleaProps<ButtonProps>;

export default Button;
