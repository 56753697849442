import WrapView from "./WrapView";

/**
 * 레이아웃 페이지
 * @param props
 * @returns
 */
function Wrap() {
  const props = {};

  return <WrapView {...props} />;
}
export default Wrap;
