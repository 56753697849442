import { useState, ReactNode, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";

import StyledSpin from "./StyledSpin";

type SpinProps = {
  children?: ReactNode | ReactNode[];
  spinning: boolean;
  size?: "small" | "medium" | "large";
  fullscreen?: boolean;
};

/**
 * 툴팁 컴포넌트
 * @param props
 * @returns
 */
const Spin: React.FC<SpinProps> = ({
  spinning = false,
  size = "medium",
  fullscreen = false,
  children,
}: SpinProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);

  const divRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setWrapperHeight(node.offsetHeight);
    }
  }, []);

  useEffect(() => {
    setIsVisible(spinning);
  }, [spinning]);

  useEffect(() => {
    if (isVisible && fullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible, fullscreen]);

  return (
    <>
      {isVisible && (
        <>
          {fullscreen ? (
            ReactDOM.createPortal(
              <StyledSpin
                $height={wrapperHeight}
                size={size}
                fullscreen={fullscreen}
              />,
              document.body,
            )
          ) : (
            <div
              ref={divRef}
              style={{
                // width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <StyledSpin
                $height={wrapperHeight}
                size={size}
                fullscreen={fullscreen}
              />
              {children}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Spin;
