import { Suspense } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

import Aside from "../Aside";
// import Footer from "../Footer";
import Header from "../Header";
import { Spin } from "@common";

export default function WrapView() {
  return (
    <>
      <Header />
      <StyledContent>
        <Aside />
        <div className="contents">
          <Suspense fallback={<Spin spinning={true} />}>
            <Outlet />
          </Suspense>
        </div>
      </StyledContent>
      {/* <Footer /> */}
    </>
  );
}

const StyledContent = styled.div`
  display: flex;
  padding-top: 6.4rem;
  min-height: calc(100vh - 150px);
  /* aside {
    flex: 0 0 220px;
  } */
  .contents {
    flex: 1;
    padding: 2.4rem 2.4rem 2.4rem calc(22rem + 2.4rem);
    /* min-width: 1180px;
    max-width: 1660px; */
    min-height: calc(100vh - 150px);
    > div,
    > form {
      /* min-width: 1120px;
      max-width: 1600px; */
      height: 100%;
      min-height: calc(100vh - (150px + 80px));
      margin: 0 auto;
    }
  }
  /* @media all and (max-width: 1920px) {
    .contents {
      min-width: 1180px;
      max-width: 1660px;
    }
  } */
`;
