import styled from "styled-components";
import Spinner from "./Spinner";
import { ReactNode } from "react";

interface Container {
  $height: number;
  children?: ReactNode | ReactNode[];
}

const StyledSpin = styled(Spinner)<Container>`
  &.spinner-wrapper {
    position: absolute;
    height: ${({ $height }) => ($height ? `${$height}px` : "auto")};
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;

    &.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      overflow: hidden;
    }
  }

  &.loading {
    position: absolute;
    /* top: 50%;
    transform-origin: 50% 43.3%; */
    @keyframes loading-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation: loading-animation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* spinner m[default] */
    &.spinner-s {
      width: 20px;
      height: 20px;
    }
    &:not(.spinner-s, .spinner-l) {
      width: 24px;
      height: 24px;
    }
    &.spinner-l {
      width: 40px;
      height: 40px;
    }
  }
`;

export default StyledSpin;
