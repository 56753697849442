/**********************************************************************
 * 라이브러리 선언부
 **********************************************************************/
/**********************************************************************
 * 커스텀 컴포넌트 선언부
 **********************************************************************/
import HeaderView from "./HeaderView";
/**********************************************************************
 * 유틸리티 선언부
 **********************************************************************/

/**
 * 헤더 page
 * @returns
 */
function Header() {
  /**********************************************************************
   * initialized
   * ref, props 가공등
   **********************************************************************/
  const props = {};

  /**********************************************************************
   * HTML rendering
   **********************************************************************/
  return (
    <>
      <HeaderView {...props} />
    </>
  );
}

export default Header;
