import { useLocation } from "react-router-dom";
import AsideView from "./AsideView";
import { menuList } from "./menuList";

/**
 * 왼쪽 사이드 컴포넌트
 * @param props
 * @returns
 */
export default function Aside() {
  const { pathname } = useLocation();

  /**
   * aside props
   */
  const asideProps = {
    pathname,
    menuList,
  };

  return <AsideView {...asideProps} />;
}
