import Button from "./Button";
import { ButtonProps } from "./Helper";
import { LoadingProvider } from "@hooks/useLoading/LoadingProvider";

/**
 * 버튼 : provider 설정
 * @param props
 * @returns
 */
const BaseButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <LoadingProvider>
      <Button {...props} />
    </LoadingProvider>
  );
};

export default BaseButton;
