/**********************************************************************
 * 라이브러리 선언부
 **********************************************************************/
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "@assets/AuroraFlow.webp";
/**********************************************************************
 * 커스텀 컴포넌트 선언부
 **********************************************************************/
/**********************************************************************
 * 유틸리티 선언부
 **********************************************************************/
/**
 * 헤더 view 페이지
 * @param param0
 * @returns
 */
export default function HeaderView() {
  /**********************************************************************
   * HTML rendering
   **********************************************************************/
  return (
    <>
      <StyledHeader>
        <h1>
          <StyledLink to={"/"}>
            <img src={Logo} width={30} />
          </StyledLink>
          <strong>AuroraFlow</strong>
        </h1>
        <StyledLink to={"/aurora/checkbox"}>Components</StyledLink>
      </StyledHeader>
    </>
  );
}

const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6.4rem;
  padding: 0 2.4rem;
  border-bottom: 1px solid var(--color-black25);
  background-color: var(--color-white);
  z-index: 1000;
  h1 {
    display: flex;
    align-items: center;
    height: 4rem;
    svg {
      width: 4rem;
    }
    strong {
      display: inline-block;
      margin-left: 1rem;
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 1;
      span {
        display: block;
        font-weight: 500;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  display
`;
