import React, { ReactNode, CSSProperties, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";

import { Button } from "@common";

export interface ModalPropsTypes {
  open: boolean;
  onClose?: () => void;
  title?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
  classNames?: string;
  style?: CSSProperties;
  cancelButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  cancelText?: string;
  centered?: boolean;
  closeIcon?: ReactNode;
  confirmLoading?: boolean;
  forceRender?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  modalRender?: (node: ReactNode) => ReactNode;
  okButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  okText?: string;
  width?: string | number;
  wrapClassName?: string;
  zIndex?: number;
}

const Modal: React.FC<ModalPropsTypes> = ({
  open,
  onClose,
  title,
  children,
  footer,
  onCancel,
  onOk,
  classNames,
  style,
  cancelButtonProps,
  cancelText = "Cancel",
  centered,
  closeIcon = "X",
  confirmLoading,
  mask = true,
  maskClosable = true,
  modalRender,
  okButtonProps,
  okText = "OK",
  width = 550,
  wrapClassName,
  zIndex,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleOverlayClick = () => {
    if (maskClosable && onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderedFooter = footer || (
    <ModalFooter>
      <ModalButton
        {...cancelButtonProps}
        onClick={onCancel || onClose}
      >
        {cancelText}
      </ModalButton>
      <ModalButton
        {...okButtonProps}
        $primary
        onClick={onOk}
        disabled={confirmLoading}
      >
        {okText}
      </ModalButton>
    </ModalFooter>
  );

  const modalBody = (
    <ModalWrapper
      $centered={centered}
      style={style}
      width={width}
      zIndex={zIndex}
      className={wrapClassName}
      onClick={handleModalClick}
    >
      <ModalHeader>
        {title}
        {closeIcon && (
          <CloseIconStyled onClick={onClose}>{closeIcon}</CloseIconStyled>
        )}
      </ModalHeader>
      <ModalBody className={classNames}>{children}</ModalBody>
      {renderedFooter}
    </ModalWrapper>
  );

  useEffect(() => {
    setIsActive(open);
  }, [open]);

  if (!isActive) return null;

  return ReactDOM.createPortal(
    <Overlay
      $mask={mask}
      onClick={handleOverlayClick}
    >
      {modalRender ? modalRender(modalBody) : modalBody}
    </Overlay>,
    document.body
  );
};

export default Modal;

const Overlay = styled.div<{ $mask?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $mask }) =>
    $mask ? "rgba(0, 0, 0, 0.5)" : "transparent"};
`;

const ModalWrapper = styled.div<{
  $centered?: boolean;
  zIndex?: number;
  width?: string | number;
}>`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: ${({ width }) => (width ? width + "px" : "520px")};
  z-index: ${({ zIndex }) => zIndex || 1000};
  ${({ $centered }) =>
    $centered &&
    css`
      margin: auto;
    `}
`;

const ModalHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  padding: 16px;
`;

const ModalFooter = styled.div`
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
  text-align: right;
`;

const ModalButton = styled(Button)<{ $primary?: boolean }>`
  padding: 6px 12px;
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ $primary }) => ($primary ? "#1890ff" : "#f5f5f5")};
  color: ${({ $primary }) => ($primary ? "#fff" : "#595959")};
  &:hover {
    opacity: 0.8;
  }
`;

const CloseIconStyled = styled.span`
  cursor: pointer;
`;
