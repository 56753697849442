type SpinnerProps = {
  className?: string;
  size: string;
  fullscreen?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({
  className,
  size,
  fullscreen,
}: SpinnerProps) => {
  const sizeClassName =
    size === "large" ? "spinner-l" : size === "small" ? "spinner-s" : "";

  return (
    <div
      className={`${className} spinner-wrapper ${
        fullscreen ? "fullscreen" : ""
      }`}
    >
      <div className={`${className} loading ${sizeClassName}`}>
        {size === "large" ? (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36 19.9998C36 28.8363 28.8365 35.9998 20 35.9998C11.1634 35.9998 3.99997 28.8363 3.99997 19.9998C3.99997 18.6743 5.07449 17.5998 6.39997 17.5998C7.72545 17.5998 8.79997 18.6743 8.79997 19.9998C8.79997 26.1853 13.8144 31.1998 20 31.1998C26.1856 31.1998 31.2 26.1853 31.2 19.9998C31.2 16.6235 29.7001 13.4973 27.1519 11.3801C25.1553 9.72129 22.6517 8.79976 20 8.79976C18.6745 8.79976 17.6 7.72524 17.6 6.39976C17.6 5.07427 18.6745 3.99976 20 3.99976C23.7826 3.99976 27.3687 5.31973 30.2193 7.68814C33.8538 10.7078 36 15.1811 36 19.9998Z"
              fill="black"
              fillOpacity="0.1"
            />
          </svg>
        ) : size === "medium" ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6 11.9999C21.6 17.3018 17.3019 21.5999 12 21.5999C6.69804 21.5999 2.39997 17.3018 2.39997 11.9999C2.39997 11.2046 3.04469 10.5599 3.83998 10.5599C4.63527 10.5599 5.27997 11.2046 5.27997 11.9999C5.27997 15.7112 8.28862 18.7199 12 18.7199C15.7113 18.7199 18.72 15.7112 18.72 11.9999C18.72 9.97409 17.8201 8.09838 16.2911 6.82808C15.0932 5.83277 13.591 5.27986 12 5.27986C11.2047 5.27986 10.56 4.63515 10.56 3.83986C10.56 3.04457 11.2047 2.39986 12 2.39986C14.2696 2.39986 16.4212 3.19184 18.1316 4.61289C20.3123 6.42468 21.6 9.10867 21.6 11.9999Z"
              fill="black"
              fillOpacity="0.1"
            />
          </svg>
        ) : size === "small" ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 9.99988C18 14.4182 14.4183 17.9999 10 17.9999C5.58172 17.9999 2 14.4182 2 9.99988C2 9.33714 2.53726 8.79988 3.2 8.79988C3.86274 8.79988 4.4 9.33714 4.4 9.99988C4.4 13.0927 6.90721 15.5999 10 15.5999C13.0928 15.5999 15.6 13.0927 15.6 9.99988C15.6 8.31174 14.8501 6.74865 13.576 5.69006C12.5777 4.86064 11.3259 4.39988 10 4.39988C9.33726 4.39988 8.8 3.86262 8.8 3.19988C8.8 2.53714 9.33726 1.99988 10 1.99988C11.8913 1.99988 13.6844 2.65986 15.1097 3.84407C16.9269 5.3539 18 7.59055 18 9.99988Z"
              fill="black"
              fillOpacity="0.1"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Spinner;
