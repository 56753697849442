export const menuList = [
  {
    title: "Checkbox",
    url: "/aurora/checkbox",
  },
  // {
  //   title: "Form",
  //   url: "/aurora/form",s
  // },
  {
    title: "Input",
    url: "/aurora/input",
  },
  {
    title: "InputNumber",
    url: "/aurora/inputNumber",
  },
  {
    title: "Radio",
    url: "/aurora/radio",
  },
  {
    title: "Select",
    url: "/aurora/select",
  },
  {
    title: "Switch",
    url: "/aurora/switch",
  },
  {
    title: "Upload",
    url: "/aurora/upload",
  },
  {
    title: "Avatar",
    url: "/aurora/avatar",
  },
  // {
  //   title: "Button",
  //   url: "/aurora/button",
  // },
  {
    title: "Typography",
    url: "/aurora/typography",
  },
  // {
  //   title: "Badge",
  //   url: "/aurora/badge",
  // },
  // {
  //   title: "Tooltip",
  //   url: "/aurora/tooltip",
  // },
  {
    title: "Skeleton",
    url: "/aurora/skeleton",
  },
  {
    title: "Card",
    url: "/aurora/card",
  },
  {
    title: "Spin",
    url: "/aurora/spin",
  },
  {
    title: "Divider",
    url: "/aurora/divider",
  },
  {
    title: "Breadcrumb",
    url: "/aurora/breadcrumb",
  },

  {
    title: "Dropdown",
    url: "/aurora/dropdown",
  },
  // {
  //   title: "Editor",
  //   url: "/aurora/editor",
  // },

  // {
  //   title: "Icon",
  //   url: "/aurora/icon",
  // },

  // {
  //   title: "Tabs",
  //   url: "/aurora/tabs",
  // },
  // {
  //   title: "Tag",
  //   url: "/aurora/tag",
  // },

  {
    title: "Modal",
    url: "/aurora/modal",
  },

  // {
  //   title: "Menu",
  //   url: "/aurora/menu",
  // },

  {
    title: "Table",
    url: "/aurora/table",
  },
  {
    title: "Pagination",
    url: "/aurora/pagination",
  },
  // {
  //   title: "Rolling",
  //   url: "/aurora/rolling",
  // },
  {
    title: "Codeblock",
    url: "/aurora/codeblock",
  },
];
