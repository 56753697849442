import { ReactNode } from "react";
import styled from "styled-components";

interface RowProps {
  children?: ReactNode;
  className?: string;
  flexdirection?: string;
  flexwrap?: string;
  aligncontent?: string;
  justifycontent?: string;
  alignitems?: string;
  gap?: string;
}

const Row: React.FC<RowProps> = ({
  children,
  className,
  ...rest
}: RowProps) => {
  return (
    <StyledRow
      className={`row ${className ? className : ""}`}
      {...rest}
    >
      {children}
    </StyledRow>
  );
};

export default Row;

const StyledRow = styled.div<{
  flexdirection?: string;
  flexwrap?: string;
  aligncontent?: string;
  justifycontent?: string;
  alignitems?: string;
  gap?: string;
}>`
  ${(props) => {
    let css = ``;
    if (props.flexdirection) {
      css += `flex-direction : ${props.flexdirection};`;
    }
    if (props.flexwrap) {
      css += `flex-wrap : ${props.flexwrap};`;
    }
    if (props.aligncontent) {
      css += `align-content : ${props.aligncontent};`;
    }
    if (props.justifycontent) {
      css += `justify-content : ${props.justifycontent};`;
    }
    if (props.alignitems) {
      css += `align-items : ${props.alignitems};`;
    }
    if (props.gap) {
      css += `gap : ${props.gap};`;
    }
    return css;
  }}
`;
