import React from "react";
import styled from "styled-components";

// ItemType 인터페이스 정의
export interface ItemType {
  title: string | React.ReactNode;
  href?: string;
  type?: "separator";
  separator?: string;
}

// BreadcrumbProps 인터페이스 정의
interface BreadcrumbProps {
  items: ItemType[];
  separator?: React.ReactNode;
}

// 페이지 이동 핸들러 함수
const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  href: string
) => {
  event.preventDefault();
  // 페이지 이동 로직 구현
  console.log(`Navigate to ${href}`);
  window.location.href = href; //페이지 이동
};

// Breadcrumb 컴포넌트 정의
const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, separator = ">" }) => {
  return (
    <BreadcrumbList>
      {items.map((item, index) => (
        <BreadcrumbItem key={index}>
          {item.href ? (
            <Link
              href={item.href}
              onClick={(event) => item.href && handleClick(event, item.href)}
            >
              {item.title}
            </Link>
          ) : (
            <>{item.title}</>
          )}
          {index < items.length - 1 && (
            <Separator>
              {item.type === "separator" ? item.separator : separator}
            </Separator>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbList>
  );
};

export default Breadcrumb;

// 스타일 컴포넌트 정의
const BreadcrumbList = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
`;

const BreadcrumbItem = styled.span`
  margin-right: 8px;

  &:last-child:after {
    content: "";
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: #f0f0f0; // 옅은 회색 배경
  }
`;

const Separator = styled.span`
  margin-left: 8px;
`;
