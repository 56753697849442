import React, { createContext, useState, ReactNode } from "react";
import ReactDOM from "react-dom";
import { Spin } from "@common";

export interface SpinContextType {
  show: () => void;
  hide: () => void;
}

export const SpinContext = createContext<SpinContextType | undefined>(
  undefined
);

interface SpinProviderProps {
  children: ReactNode;
}

export const SpinProvider: React.FC<SpinProviderProps> = ({ children }) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const show = () => setIsShowing(true);
  const hide = () => setIsShowing(false);

  return (
    <SpinContext.Provider value={{ show, hide }}>
      {children}
      {isShowing
        ? ReactDOM.createPortal(
            <Spin
              spinning={true}
              fullscreen
            />,
            document.body
          )
        : null}
    </SpinContext.Provider>
  );
};

export default SpinProvider;
