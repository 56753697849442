import { ReactNode } from "react";
import SpinProvider from "@/hooks/useSpin/SpinProvider";
import { ModalProvider } from "@/context/ModalContext";
import "./index.css";

/** ===== Provider ===== */
function Provider({ children }: ProviderProps) {
  return (
    <ModalProvider>
      <SpinProvider>{children}</SpinProvider>
    </ModalProvider>
  );
}

/** ===== Types ===== */
type ProviderProps = { children: ReactNode };

/** ===== Exports ===== */
export default Provider;
